import ApiInstance from './ApiInstance.js'

export default {
  
  getCareerLevel() {
    return ApiInstance({ requiresAuth: true }).get('/career-level');
  },
  addCareerLevel(Data) {
    return ApiInstance({ requiresAuth: true }).post('/career-level', Data);
  },
  editCareerLevel(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/career-level/${id}`, Data);
  },
  deleteCareerLevel(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/career-level/${id}`);
  }
}
